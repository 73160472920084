const NavLinks = [
  {
    children: "USD",
    symbol: "$",
    key: 1,
  },
  {
    children: "EUR",
    symbol: "€",
    class: "middle-a",
    key: 2,
  },
  {
    children: "GBP",
    symbol: "£",
    key: 3,
  }
];

export default NavLinks;
